import React, { PropsWithChildren, useState } from "react";

export const ProjectsContext = React.createContext({
  projects: [],
  setProjects: (items: any) => {},
});

export const RootLayout = ({ children }: PropsWithChildren) => {
  const [projects, setProjects] = useState([]);

  return (
    <ProjectsContext.Provider
      value={{ projects: projects, setProjects: setProjects }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
